body {
  /* padding: 20px; */
  background-color: #F0F2F5 !important;
}

.nav-link.active {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25) !important;
}

.nav-link {
  /* padding-left: 50%;
  padding-right: 50%; */
  border-radius: 12px !important;
  color: #495057;
  padding: 8px 25px !important;
}

ul.mb-2.nav.nav-tabs {
  border-bottom: none;
  display: inline-flex;
  background-color: #f8f9fa !important;
  border-radius: 8px;
  padding: 1% 2%;
}

.ant-menu>.ant-menu-item:hover {
  background-color: #4F4F52 !important;
}

.ant-menu>.ant-menu-item-selected:hover {
  background-color: #E91F63 !important;
}

.ant-menu-item-selected {
  background-color: #E91F63 !important;
}

.placeholder {
  background-color: #FFFFFF;
  display: block;
  font-weight: 400;
  border: none;
  color: #7B809A;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #C7CCD0;
  cursor: pointer;
}


.table-card{
  /* height:"75vh", borderRadius:"12px", boxShadow:"0px 2px 6px rgba(0, 0, 0, 0.25)" */
  height: 68vh;
  border-radius: 12px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  /* media query set height 75 when sceen smol */
}

.api-code-card{
  /* {{ height: "52vh", borderRadius: "12px", boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)" }} */
  height: 52vh;
  border-radius: 12px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);

}

.download-button {
  border: none;
  background: none;
  color: rgb(39, 140, 223);
}

.custom-hover:hover{
  color: #fff !important;
  background-color: #dc3545 !important;
}

.text_border {
  padding: 5px;
  /* border: 1px solid black; */
  border-radius: 3px;
}

.shop_text {
  color: #dc3545;
  font-size: 18px;
  padding: 0rem 0rem 0.5rem 0rem;
}

.shop_text:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .table-card {
    height: 75vh;
  }
}

/* media query for max-width 870 */

@media screen and (max-width: 870px) {
  .nav-link {
    /* padding-left: 50%;
    padding-right: 50%; */
    border-radius: 12px !important;
    color: #495057;
    padding: 8px 10px !important;
  }
}

@media screen and (max-width: 1132px) {

  .code{
    height: 20vh
  }

}